<template>
  <div class="header flex-between">
    <div class="logo" @click="jumpTo('Home')">
      <img class="logoImg" src="@/assets/img/logo.png" />
    </div>
    <div class="lang">
      <el-select
        v-model="selectValue"
        @change="langChange"
        size="small"
        placeholder="请选择"
      >
        <el-option
          v-for="item in langOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      selectValue: '',
      langOptions: [
        {
          value: 'cn',
          label: '简体中文',
        },
        {
          value: 'zhTW',
          label: '繁体中文',
        },
        {
          value: 'en',
          label: 'English',
        },
      ],
    }
  },
  created() {
    this.selectValue = localStorage.lang == undefined ? 'cn' : localStorage.lang
  },
  methods: {
    //语言切换
    langChange(e) {
      // console.log(e)
      localStorage.setItem('lang', e)
      this.$i18n.locale = e
    },
    jumpTo(pageName) {
      this.$router.push({ name: pageName })
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  height: 50px;
  line-height: 50px;
  align-items: center;
  .el-select {
    width: 140px;
  }
  .logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    .logoImg {
      width: 140px;
    }
  }
}
</style>
