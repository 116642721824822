<template>
  <div class="register">
    <reg-header></reg-header>
    <div class="registerBox">
      <router-view />
    </div>
  </div>
</template>

<script>
import regHeader from '@/components/base/reg-header.vue'
export default {
  name: 'Login',
  components: {
    regHeader,
  },
  data() {
    return {
      ruleForm: {},
      isAgreement: false,
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.register {
  width: 1200px;
  margin: 20px auto 0;
}
</style>
